.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

ul {
    list-style: none;
}

.file-item {
    display: flex;
}

.file-link {
    text-align: center;
    text-decoration: none;
    color: black;
    margin: auto 0;
}

.file-link:visited {
    color: black;
}

.fi {
    margin: 5px !important;
    align-items: center;
    justify-content: center;
}

ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.delivery-title {
    margin-top: 20px;
    font-size: 20px;
}